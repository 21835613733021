import icon1 from './icons/icon1.svg'
import icon2 from './icons/icon2.svg'
import icon3 from './icons/icon3.svg'

export const data = [
	{
		img: icon1,
		title: 'Учим онлайн',
		subtitle: [
			'Учимся работать на площадке для онлайн уроков Miro',
			'План обучения',
		],
		description: [
			'5 видео-практикумов на ProgressMe',
			'Доступ к обучению 1 год',
			'Поддержка в телеграм',
		],
		items: [
			'Регистрация и создание личного кабинета учителя',
			'Экскурсия по инструментам для уроков',
			'Создание интерактивного онлайн урока на основе любого УМК',
			'Создание интерактивных упражнений и игр',
			'Добавление сторонних интерактивных ресурсов',
		],
		price: "1000",
		link: "https://yookassa.ru/my/i/Z0c6272S_TBH/l",
	},
	{
		img: icon2,
		title: 'Развиваем блог',
		subtitle: ['Развиваем телеграм канал преподавателя', 'План лекции'],
		description: [
			'1 час полезной информации',
			'Доступ навсегда',
			'Поддержка в телеграм',
		],
		items: [
			'Отработка страхов',
			'Выбор ниши',
			'Продвижение',
			'Монетизация',
			'Проблемы на пути',
			'Основные ошибки',
		],
		price: '1000',
		link: 'https://yookassa.ru/my/i/Z0c6tOx1fQSq/l',
	},
	{
		img: icon3,
		title: 'ИИ для уроков',
		subtitle: ['Изучаем лучшие нейросети для уроков', ''],
		description: [
			'8 видео-практикумов на ProgressMe',
			'Доступ к обучению 1 год',
			'Поддержка в телеграм',
		],
		items: [
			'Обзорный курс по самым эффективным ИИ инструментам для преподавателей',
		],
		price: '1000',
		link: 'https://yookassa.ru/my/i/Z0c6a3xNeOeu/l',
	},
]